import { DevTool } from "@hookform/devtools";
import {
  ArrowRight as ArrowRightIcon,
  GroupAdd as GroupAddIcon,
  Help as HelpIcon,
  SettingsRounded as SettingsRoundedIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useImportSyncNewADUsersMutation } from "../features/add-members/addMembersService";
import type { AddMembersByADFormData } from "../features/add-members/addMembersTypes";
import {
  addSyncedMembers,
  ADGroup,
  ADUser,
  defaultSyncADConfigs,
  getIntegrationsByPlatformName,
  MemberButtonADFieldLabelsMap,
  Platforms,
} from "../features/integrations";
import {
  addMembers,
  addNewMemberSettings,
} from "../features/members/membersSlice";
import { addNewLinks } from "../features/links/linksSlice";
import { editTemplateAssignees } from "../features/templates/templatesSlice";
import type { Template } from "../features/templates/templatesTypes";
import useTemplates from "../hooks/useTemplates";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FormControlMultiSelect } from "../styles/mui-styled";
import { FetchError, handleFetchError } from "../utils";
import Spinner from "./Spinner";
import SwitchX from "./SwitchX";
import SyncADConfigsDialog from "./SyncADConfigsDialog";
import SyncADMembers from "./SyncADMembers";

type ImportNewADMembersProps = {
  users: ADUser[];
  groups: ADGroup[];
  templateId?: Template["id"];
  className?: string;
};

function ImportNewADMembers({
  users: adUsers,
  groups: adGroups,
  templateId,
  className = "",
  ...props
}: ImportNewADMembersProps) {
  const [selectedGroups, setSelectedGroups] = useState<ADGroup[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<ADUser[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isDialogOpenedOnce, setIsDialogOpenedOnce] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  const { templateLinks, isLoading: isTemplatesLoading } = useTemplates();

  const adIntegration = useAppSelector(
    (state: RootState) =>
      getIntegrationsByPlatformName(state, Platforms.ACTIVE_DIRECTORY)?.[0]
  );

  useEffect(() => {
    if (selectedGroups.length) {
      setFilteredUsers(
        adUsers.filter(
          (aUser) =>
            !aUser.memberId &&
            aUser.groupIds?.some((aGroupId) =>
              selectedGroups.some((group) => group.id === aGroupId)
            )
        )
      );
    } else {
      setFilteredUsers(adUsers.filter((aUser) => !aUser.memberId));
    }
  }, [selectedGroups, adUsers]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<AddMembersByADFormData>({
    defaultValues: {
      selectedADUserIds: [],
      settings: defaultSyncADConfigs,
      doSendInvites: true,
    },
  });

  useEffect(() => {
    if (templateId) {
      MemberButtonADFieldLabelsMap.forEach((_, aMemberProp) => {
        setValue(
          `settings.propsToSync.${aMemberProp}`,
          { sync: false, overwrite: false },
          { shouldDirty: true }
        );
      });
    }

    setIsDialogOpenedOnce(false);
  }, [templateId, setValue]);

  function handleSelectionChange(
    rowSelectionModel: GridRowSelectionModel
  ): void {
    setValue("selectedADUserIds", rowSelectionModel as Array<ADUser["id"]>, {
      shouldDirty: true,
    });
  }

  const [syncMembersWithAD, { isLoading: isWithADPending }] =
    useImportSyncNewADUsersMutation();

  async function handleSyncNowSubmit({
    selectedADUserIds,
    settings,
    doSendInvites,
  }: AddMembersByADFormData) {
    if (!adIntegration) return;

    try {
      const { success, message, data } = await syncMembersWithAD({
        integrationId: adIntegration.id,
        selectedADUsers: filteredUsers.filter((aUser) =>
          selectedADUserIds.includes(aUser.id)
        ),
        settings,
        doSendInvites,
        ...(templateId && templateId > -1 && { templateId: templateId }),
      }).unwrap();
      if (success) {
        dispatch(addMembers(data.newMembers));
        dispatch(addNewMemberSettings(data.newsettings));
        dispatch(addNewLinks(data.newLinks));
        if (data.assignedTemplate) {
          dispatch(editTemplateAssignees(data.assignedTemplate));
        }
        dispatch(addSyncedMembers(data.syncedMembers));
        reset(getValues());
        navigateTo("/members");
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  if (isTemplatesLoading) {
    return (
      <Spinner className="relative h-full grow rounded-3xl bg-gray-50/50" />
    );
  }

  return (
    <div className={className} {...props}>
      <div className="relative flex flex-col gap-4">
        <FormControlMultiSelect className="gap-2">
          <Autocomplete
            multiple
            id="sync-groups"
            options={adGroups}
            value={selectedGroups}
            onChange={(_, v) => setSelectedGroups(v)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.displayName}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected}
                      classes={{
                        colorPrimary: "!text-gray-500",
                        checked: "!text-gray-900",
                      }}
                    />
                  }
                  label={option.displayName}
                />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Group(s)"
                placeholder="Favorites"
              />
            )}
          />
        </FormControlMultiSelect>
        <SyncADMembers
          adUsers={filteredUsers}
          onSelectionChange={handleSelectionChange}
        />
        <p className="flex items-center text-gray-400">
          <HelpIcon fontSize="small" className="mr-2" />
          <small>
            <b>1.</b> Select AD Users
          </small>
          <ArrowRightIcon />
          <small>
            <b>2.</b> Configure
          </small>
          <ArrowRightIcon />
          <small>
            <b>3.</b> Import Now
          </small>
        </p>
      </div>
      <div className="flex flex-col items-center justify-end gap-4 sm:flex-row">
        <div className="grow">
          <Controller
            name="doSendInvites"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <SwitchX
                name={name}
                checked={value}
                onChange={onChange}
                label="Send email invites"
              />
            )}
          />
        </div>
        <Button
          variant="outlined"
          startIcon={<SettingsRoundedIcon />}
          onClick={() => {
            setDialogOpen(true);
            setIsDialogOpenedOnce(true);
          }}
          className={`!m-0 ${
            !isDialogOpenedOnce &&
            getValues().selectedADUserIds.length !== 0 &&
            "animate-bounce"
          } !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black`}
        >
          Configure
        </Button>
        {dialogOpen && (
          <SyncADConfigsDialog
            links={
              templateId
                ? templateLinks?.filter(
                    (tl) => tl.templateId === templateId && tl.isUnique
                  )
                : undefined
            }
            data={getValues("settings")}
            onSave={(data) => {
              setValue("settings", data, { shouldDirty: true });
              setDialogOpen(false);
            }}
            open={true}
            onClose={() => setDialogOpen(false)}
          />
        )}
        <LoadingButton
          type="submit"
          startIcon={<GroupAddIcon />}
          loading={isWithADPending}
          loadingPosition="start"
          disabled={getValues().selectedADUserIds.length === 0 || !isDirty}
          onClick={handleSubmit(handleSyncNowSubmit)}
          classes={{
            root: `!rounded-full ${
              getValues().selectedADUserIds.length > 0 &&
              isDirty &&
              "!bg-primary"
            } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
            disabled: "!bg-gray-300",
            loadingIndicator: "!left-5",
          }}
        >
          Import Now
        </LoadingButton>
      </div>
      <DevTool control={control} />
    </div>
  );
}

export default ImportNewADMembers;
