import { DevTool } from "@hookform/devtools";
import {
  ArrowRight as ArrowRightIcon,
  ExpandMore as ExpandMoreIcon,
  Help as HelpIcon,
  SettingsRounded as SettingsRoundedIcon,
  SyncRounded as SyncRoundedIcon,
} from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import type { GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSyncExistingADUsersMutation } from "../features/add-members/addMembersService";
import type { AddMembersByADFormData } from "../features/add-members/addMembersTypes";
import {
  addSyncedMembers,
  ADGroup,
  ADUser,
  defaultSyncADConfigs,
  ExistingADMember,
  getIntegrationsByPlatformName,
  MemberButtonADFieldLabelsMap,
  Platforms,
} from "../features/integrations";
import { updateLinks } from "../features/links/linksSlice";
import { editMembers } from "../features/members/membersSlice";
import type { Template } from "../features/templates/templatesTypes";
import useIntegrations from "../hooks/useIntegrations";
import useTemplates from "../hooks/useTemplates";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FormControlMultiSelect } from "../styles/mui-styled";
import { FetchError, handleFetchError } from "../utils";
import Spinner from "./Spinner";
import SyncADConfigsDialog from "./SyncADConfigsDialog";
import SyncADMembers from "./SyncADMembers";

type UpdateExistingADMembersProps = {
  users: ADUser[];
  groups: ADGroup[];
  templateId?: Template["id"];
  onTemplateChange?: (id: Template["id"]) => void;
  className?: string;
};

function UpdateExistingADMembers({
  users: adUsers,
  groups: adGroups,
  templateId,
  onTemplateChange,
  className = "",
  ...props
}: UpdateExistingADMembersProps) {
  const [selectedGroups, setSelectedGroups] = useState<ADGroup[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<ExistingADMember[]>([]);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [isDialogOpenedOnce, setIsDialogOpenedOnce] = useState<boolean>(false);
  // const [expanded, setExpanded] = React.useState<string | false>("template-1");
  const [thisSelectedUserIds, setThisSelectedUserIds] =
    useState<GridRowSelectionModel>();

  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();

  const { syncedMembers, isLoading: isIntegrationsLoading } = useIntegrations();
  const {
    templates,
    templateLinks,
    isLoading: isTemplatesLoading,
  } = useTemplates();

  const adIntegration = useAppSelector(
    (state: RootState) =>
      getIntegrationsByPlatformName(state, Platforms.ACTIVE_DIRECTORY)?.[0]
  );

  useEffect(() => {
    if (selectedGroups.length) {
      setFilteredUsers(
        adUsers.filter(
          (aUser) =>
            aUser.memberId &&
            !syncedMembers?.some((sm) => sm.memberId === aUser.memberId) &&
            aUser.groupIds?.some((aGroupId) =>
              selectedGroups.some((group) => group.id === aGroupId)
            )
        ) as ExistingADMember[]
      );
    } else {
      setFilteredUsers(
        adUsers.filter(
          (aUser) =>
            aUser.memberId &&
            !syncedMembers?.some((sm) => sm.memberId === aUser.memberId)
        ) as ExistingADMember[]
      );
    }
  }, [adUsers, selectedGroups, syncedMembers]);

  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm<AddMembersByADFormData>({
    defaultValues: {
      selectedADUserIds: [],
      settings: defaultSyncADConfigs,
      doSendInvites: false,
    },
  });

  useEffect(() => {
    if (templateId) {
      MemberButtonADFieldLabelsMap.forEach((_, aMemberProp) => {
        setValue(
          `settings.propsToSync.${aMemberProp}`,
          { sync: false, overwrite: false },
          { shouldDirty: true }
        );
      });
    }

    setIsDialogOpenedOnce(false);
  }, [templateId, setValue]);

  function handleSelectionChange(selectedUserIds: GridRowSelectionModel): void {
    setThisSelectedUserIds(selectedUserIds);

    setValue("selectedADUserIds", selectedUserIds as Array<ADUser["id"]>, {
      shouldDirty: true,
    });

    if (onTemplateChange && selectedUserIds.length && templates?.length) {
      const firstSelectedUserMemberId = filteredUsers.find(
        (u) => u.id === selectedUserIds[0]
      )?.memberId;
      if (firstSelectedUserMemberId) {
        const selectedUsersTemplateId = templates.find((t) =>
          t.assigneeIds.includes(firstSelectedUserMemberId)
        )?.id;
        if (selectedUsersTemplateId !== templateId) {
          onTemplateChange(selectedUsersTemplateId || -1);
          setValue("settings", defaultSyncADConfigs, { shouldDirty: true });
          setIsDialogOpenedOnce(false);
        }
      }
    }
  }

  const [syncExistingADUsers, { isLoading: isWithADPending }] =
    useSyncExistingADUsersMutation();

  async function handleSyncNowSubmit({
    selectedADUserIds,
    settings,
    doSendInvites,
  }: AddMembersByADFormData) {
    if (!adIntegration) return;

    try {
      const { success, message, data } = await syncExistingADUsers({
        integrationId: adIntegration.id,
        selectedADUsers: filteredUsers.filter((aUser) =>
          selectedADUserIds.includes(aUser.id)
        ),
        settings,
        ...(templateId && templateId > -1 && { templateId: templateId }),
      }).unwrap();
      if (success) {
        dispatch(editMembers(data.updatedMembers));
        dispatch(updateLinks(data.updatedLinks));
        dispatch(addSyncedMembers(data.syncedMembers));
        reset(getValues());
        navigateTo("/members");
        toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  // function handleChange(templateIndex: string) {
  //   return (_e: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? templateIndex : false);
  //   };
  // }

  if (isTemplatesLoading || isIntegrationsLoading) {
    return (
      <Spinner className="relative h-full grow rounded-3xl bg-gray-50/50" />
    );
  }

  return (
    <div className={className} {...props}>
      <div className="relative flex flex-col gap-4">
        <FormControlMultiSelect className="gap-2">
          <Autocomplete
            multiple
            id="sync-groups"
            options={adGroups}
            value={selectedGroups}
            onChange={(_, v) => setSelectedGroups(v)}
            disableCloseOnSelect
            getOptionLabel={(option) => option.displayName}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selected}
                      classes={{
                        colorPrimary: "!text-gray-500",
                        checked: "!text-gray-900",
                      }}
                    />
                  }
                  label={option.displayName}
                />
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Filter by Group(s)"
                placeholder="Favorites"
              />
            )}
          />
        </FormControlMultiSelect>
        <Box className="grid gap-2">
          {templates
            ?.filter((aTemplate) =>
              filteredUsers.some(
                (aUser) =>
                  aUser.memberId &&
                  aTemplate.assigneeIds.includes(aUser.memberId)
              )
            )
            .map((aTemplate, index) => (
              <Accordion
                key={aTemplate.id}
                defaultExpanded={aTemplate.id === templateId}
                // expanded={expanded === `template-${aTemplate.id}`}
                // onChange={handleChange(`template-${aTemplate.id}`)}
                className="!m-0 !rounded-2xl !bg-gray-100 !shadow-none before:hidden"
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`template-${aTemplate.id}-content`}
                  id={`template-${aTemplate.id}-header`}
                  classes={{
                    root: "!min-h-12",
                    content: "!my-3 !text-base !font-semibold",
                  }}
                >
                  {aTemplate.name}
                </AccordionSummary>
                <AccordionDetails className="!p-0">
                  <SyncADMembers
                    adUsers={filteredUsers.filter((aUser) =>
                      aTemplate.assigneeIds.includes(aUser.memberId)
                    )}
                    selectedUserIds={thisSelectedUserIds}
                    onSelectionChange={handleSelectionChange}
                    className="!rounded-2xl bg-white"
                  />
                </AccordionDetails>
              </Accordion>
            ))}
          <Accordion
            defaultExpanded={!templateId}
            className={`!m-0 !rounded-2xl !bg-gray-100 !shadow-none before:hidden`}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="template--1-content"
              id="template--1-header"
              classes={{
                root: "!min-h-12",
                content: "!my-3 !text-base !font-semibold",
              }}
            >
              Others
            </AccordionSummary>
            <AccordionDetails className="!p-0">
              <SyncADMembers
                adUsers={filteredUsers.filter((aUser) =>
                  templates?.every(
                    (aTemplate) =>
                      !aTemplate.assigneeIds.includes(aUser.memberId)
                  )
                )}
                selectedUserIds={thisSelectedUserIds}
                onSelectionChange={handleSelectionChange}
                className="!rounded-2xl bg-white"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <p className="flex items-center text-gray-400">
          <HelpIcon fontSize="small" className="mr-2" />
          <small>
            <b>1.</b> Select AD Users
          </small>
          <ArrowRightIcon />
          <small>
            <b>2.</b> Configure
          </small>
          <ArrowRightIcon />
          <small>
            <b>3.</b> Sync Now
          </small>
        </p>
      </div>
      <div className="flex flex-col items-center justify-end gap-4 sm:flex-row">
        <Button
          variant="outlined"
          startIcon={<SettingsRoundedIcon />}
          onClick={() => {
            setDialogOpen(true);
            setIsDialogOpenedOnce(true);
          }}
          className={`!m-0 ${
            !isDialogOpenedOnce &&
            getValues().selectedADUserIds.length !== 0 &&
            "animate-bounce"
          } !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black`}
        >
          Configure
        </Button>
        {dialogOpen && (
          <SyncADConfigsDialog
            links={
              templateId
                ? templateLinks?.filter(
                    (tl) => tl.templateId === templateId && tl.isUnique
                  )
                : undefined
            }
            data={getValues("settings")}
            onSave={(data) => {
              setValue("settings", data, { shouldDirty: true });
              setDialogOpen(false);
            }}
            open={true}
            onClose={() => setDialogOpen(false)}
          />
        )}
        <LoadingButton
          type="submit"
          startIcon={<SyncRoundedIcon />}
          loading={isWithADPending}
          loadingPosition="start"
          disabled={getValues().selectedADUserIds.length === 0 || !isDirty}
          onClick={handleSubmit(handleSyncNowSubmit)}
          classes={{
            root: `!rounded-full ${
              getValues().selectedADUserIds.length > 0 &&
              isDirty &&
              "!bg-primary"
            } !px-6 !py-3 !font-sans !text-sm !normal-case !text-white`,
            disabled: "!bg-gray-300",
            loadingIndicator: "!left-5",
          }}
        >
          Sync Now
        </LoadingButton>
      </div>
      <DevTool control={control} />
    </div>
  );
}

export default UpdateExistingADMembers;
