import type {
  ReadDevicesAPIResponse,
  ReadDevicesResponse,
  UpdateProductDetailsAPIResponse,
  UpdateProductDetailsResponse,
} from "./devicesTypes";

export function mapReadDevicesResponse(
  response: ReadDevicesAPIResponse
): ReadDevicesResponse {
  return {
    success: response.success,
    message: response.message,
    data: response.data.map((device) => ({
      id: device.id,
      assignedTo: device.assigned_to,
      serialNumber: device.serial_number,
      productImage: device.product_image,
      productType: device.product_type,
      name: device.name ?? "",
      activatedOn: device.activated_on ?? "",
      lastScannedOn: device.last_scanned_on ?? "",
      lastScannedAt: device.last_scanned_at ?? "",
    })),
  };
}

export function transformUpdateProductDetailsResponse(
  response: UpdateProductDetailsAPIResponse
): UpdateProductDetailsResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      id: response.data.id,
      ...(response.data.name && { name: response.data.name }),
    },
  };
}
