import {
  CloudDownload as CloudDownloadIcon,
  Update as UpdateIcon,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  ImportNewADMembers,
  MissingMembersDialog,
  Spinner,
  tabPanelA11yProps,
  TabsX,
  TabX,
  UpdateExistingADMembers,
} from "../../components";
import useIntegrations from "../../hooks/useIntegrations";
import { RootState, useAppSelector } from "../../store";
import { FetchError, handleFetchError } from "../../utils";
import {
  ADGroup,
  ADUser,
  Platforms,
  getIntegrationsByPlatformName,
  getPlatformByName,
  useReadADGroupsUsersMutation,
} from "../integrations";
import ActiveDirectoryIntegration from "../integrations/ActiveDirectoryIntegration";
import type { Member } from "../members/membersTypes";
import type { Template } from "../templates/templatesTypes";
import {
  AddMembersByADSections,
  getAddMembersByADSectionLabel,
} from "./addMembersEnums";
import { useKeepMissingUsersMutation } from "./addMembersService";

type SyncActiveDirectoryProps = {
  templateId?: Template["id"];
  onTemplateChange?: (id: Template["id"]) => void;
  className?: string;
};

function SyncActiveDirectory({
  templateId,
  onTemplateChange,
  className = "",
  ...props
}: SyncActiveDirectoryProps) {
  const [adGroups, setADGroups] = useState<ADGroup[]>([]);
  const [adUsers, setADUsers] = useState<ADUser[]>([]);
  const [missingMemberIdsToDelete, setMissingMemberIdsToDelete] = useState<
    Array<Member["id"]>
  >([]);
  const [tabIndex, setTabIndex] = useState<AddMembersByADSections>(
    AddMembersByADSections.NEW
  );

  const { isLoading: isIntegrationsLoading } = useIntegrations();

  const activeDirectoryPlatform = useAppSelector((state: RootState) =>
    getPlatformByName(state, Platforms.ACTIVE_DIRECTORY)
  );

  const activeDirectoryIntegration = useAppSelector(
    (state: RootState) =>
      getIntegrationsByPlatformName(state, Platforms.ACTIVE_DIRECTORY)?.[0]
  );

  const [readADGroupsUsers, { isLoading: isReadADGroupsUsersLoading }] =
    useReadADGroupsUsersMutation();

  useEffect(() => {
    if (activeDirectoryIntegration) {
      (async function () {
        try {
          const {
            success,
            message,
            data: {
              adGroups: thisADGroups,
              adUsers: thisADUsers,
              missingMemberIds,
            },
          } = await readADGroupsUsers(activeDirectoryIntegration.id).unwrap();
          if (success) {
            setADGroups(thisADGroups);
            setADUsers(thisADUsers);
            if (missingMemberIds?.length) {
              setMissingMemberIdsToDelete(missingMemberIds);
            }
          } else {
            toast.error(message);
          }
        } catch (error) {
          handleFetchError(error as FetchError);
        }
      })();
    }
  }, [activeDirectoryIntegration, readADGroupsUsers]);

  const [keepMissingUsers] = useKeepMissingUsersMutation();

  async function keepAllMissingMembers(memberIdsToKeep: Array<Member["id"]>) {
    if (!activeDirectoryIntegration || memberIdsToKeep.length < 1) return;

    try {
      const { success, message } = await keepMissingUsers({
        integrationId: activeDirectoryIntegration.id,
        memberIdsToKeep,
      }).unwrap();
      if (success) {
        setMissingMemberIdsToDelete([]);
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <div className={`flex min-h-full flex-col gap-4 ${className}`} {...props}>
      <div className="flex flex-col">
        <h4 className="text-xl font-semibold text-black">
          Sync with Active Directory
        </h4>
        <p className="text-sm text-gray-500">
          Sync Microsoft Active Directory Members to AddMee. Only the AD Members
          with an Email or Other emails are listed below.
        </p>
      </div>
      {isIntegrationsLoading || isReadADGroupsUsersLoading ? (
        <Spinner className="relative h-full grow rounded-3xl bg-gray-50/50" />
      ) : activeDirectoryIntegration ? (
        <>
          <TabsX
            name="Add Members By Active Directory"
            value={tabIndex}
            onChange={(_e, v) => setTabIndex(v)}
          >
            <TabX
              icon={<CloudDownloadIcon />}
              label={getAddMembersByADSectionLabel(AddMembersByADSections.NEW)}
              index={AddMembersByADSections.NEW}
            />
            <TabX
              icon={<UpdateIcon />}
              label={getAddMembersByADSectionLabel(
                AddMembersByADSections.EXISTING
              )}
              index={AddMembersByADSections.EXISTING}
            />
          </TabsX>
          {tabIndex === AddMembersByADSections.NEW && (
            <ImportNewADMembers
              users={adUsers}
              groups={adGroups}
              templateId={templateId}
              {...tabPanelA11yProps(AddMembersByADSections.NEW)}
            />
          )}
          {tabIndex === AddMembersByADSections.EXISTING && (
            <UpdateExistingADMembers
              users={adUsers}
              groups={adGroups}
              templateId={templateId}
              onTemplateChange={onTemplateChange}
              {...tabPanelA11yProps(AddMembersByADSections.NEW)}
            />
          )}
          <MissingMembersDialog
            ids={missingMemberIdsToDelete}
            open={missingMemberIdsToDelete.length > 0}
            onDelete={() => setMissingMemberIdsToDelete([])}
            onKeepAll={keepAllMissingMembers}
            onClose={() => setMissingMemberIdsToDelete([])}
            className="!max-w-3xl"
          />
        </>
      ) : (
        <>
          {activeDirectoryPlatform && (
            <ActiveDirectoryIntegration
              platform={activeDirectoryPlatform}
              className="w-1/2 border border-gray-200"
            />
          )}
        </>
      )}
    </div>
  );
}

export default SyncActiveDirectory;
