import { getBoolean, getRGBAString, getSVGString } from "../../utils";
import { AddedToContactCardBy } from "../links/linksEnums";
import { SettingsColorsDefaults } from "../settings/settingsEnums";
import { UserRole } from "../users/usersEnums";
import type {
  CreateMembersByCSVAPIResponse,
  CreateMembersByCSVRequestArgs,
  CreateMembersByEmailAPIResponse,
  CreateMembersByEmailRequestArgs,
  CreateMembersByEmailRequestBody,
  CreateMembersResponse,
  ImportNewADUsersAPIResponse,
  ImportNewADUsersRequestArgs,
  ImportNewADUsersRequestBody,
  ImportNewADUsersResponse,
  SyncExistingADUsersAPIResponse,
  SyncExistingADUsersRequestArgs,
  SyncExistingADUsersRequestBody,
  SyncExistingADUsersResponse,
} from "./addMembersTypes";

export function getCreateMembersByEmailRequestBody(
  args: CreateMembersByEmailRequestArgs
): CreateMembersByEmailRequestBody {
  return {
    new_members: args.newMembers.map((aMember) => ({
      first_name: aMember.fullName.split(" ")[0],
      last_name: aMember.fullName.split(" ").slice(1).join(" ").trim(),
      email: aMember.email,
    })),
    send_invites: args.doSendInvites,
    template_id: args.templateId,
  };
}

export function mapCreateMembersByEmailResponse(
  response: CreateMembersByEmailAPIResponse
): CreateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.members?.map((aNewMember) => ({
        id: aNewMember.id,
        username: aNewMember.username,
        email: aNewMember.email,
        membership: aNewMember.is_pro,
        firstName: aNewMember.first_name ?? "",
        fullName: [aNewMember.first_name, aNewMember.last_name]
          .join(" ")
          .trim(),
        designation: "",
        companyName: "",
        companyAddress: "",
        subtitle: "",
        profileBanner: "",
        profileImage: "",
        companyLogo: "",
        viewsCount: 0,
        leadsCount: 0,
        devicesCount: 0,
        locked: false,
        isPublic: true,
        openDirect: false,
        captureLead: false,
        connectButton: true,
        saveContactButton: true,
        role: UserRole.BUSINESS_USER,
      })),
      settings: response.data.settings?.map((aNewUserSettings) => ({
        colors: {
          id: aNewUserSettings.colors.id,
          userId: aNewUserSettings.colors.user_id,
          sectionColor:
            getRGBAString(aNewUserSettings.colors.section_color ?? "") ||
            SettingsColorsDefaults.SECTION_COLOR,
          profileColor:
            getRGBAString(aNewUserSettings.colors.bg_color ?? "") ||
            SettingsColorsDefaults.PROFILE_COLOR,
          borderColor:
            getRGBAString(aNewUserSettings.colors.photo_border_color ?? "") ||
            SettingsColorsDefaults.BORDER_COLOR,
          textColor:
            getRGBAString(aNewUserSettings.colors.text_color ?? "") ||
            SettingsColorsDefaults.TEXT_COLOR,
          controlButtonTextColor:
            getRGBAString(
              aNewUserSettings.colors.control_button_text_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
          controlButtonBgColor:
            getRGBAString(aNewUserSettings.colors.control_button_color ?? "") ||
            SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
          profileButtonIconColor:
            getRGBAString(
              aNewUserSettings.colors.profile_button_icon_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
          profileButtonBgColor:
            getRGBAString(
              aNewUserSettings.colors.profile_button_bg_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
          colorLinkIcons:
            getBoolean(aNewUserSettings.colors.color_link_icons) ||
            Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
        },
      })),
      links: response.data.links?.map((aNewLink) => ({
        id: aNewLink.id,
        linkTypeCode: aNewLink.profile_code,
        iconSVG: getSVGString(aNewLink.icon_url) || aNewLink.icon_svg || "",
        title: aNewLink.title,
        valueType: aNewLink.type,
        value: aNewLink.value,
        href: aNewLink.href,
        sequence: aNewLink.sequence,
        visible: Boolean(aNewLink.visible),
        isHighlighted: Boolean(aNewLink.is_highlighted),
        userId: aNewLink.user_id,
        globalId: aNewLink.global_id || null,
        templateId: aNewLink.template_id || null,
        isUnique: Boolean(aNewLink.is_unique),
        isAddedToContactCardBy:
          aNewLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aNewLink.template_link_id || null,
        syncedWith: null,
      })),
      ...(response.data.template && {
        assignedTemplate: {
          id: response.data.template.id,
          assigneeIds: response.data.template.assignees_ids.map((id) =>
            Number(id)
          ),
        },
      }),
    },
  };
}

export function getCreateMembersByCSVRequestBody(
  args: CreateMembersByCSVRequestArgs
): FormData {
  const formData = new FormData();
  formData.append("file", args.file);
  formData.append("send_invites", String(args.doSendInvites));
  formData.append(
    "mapped_fields",
    JSON.stringify(Object.fromEntries(args.mappedFields.entries()))
  );
  if (args.templateId) {
    formData.append("template_id", String(args.templateId));
  }
  return formData;
}

export function mapCreateMembersByCSVResponse(
  response: CreateMembersByCSVAPIResponse
): CreateMembersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      members: response.data.members?.map((aNewMember) => ({
        id: aNewMember.id,
        username: aNewMember.username,
        email: aNewMember.email,
        membership: aNewMember.is_pro,
        firstName: aNewMember.first_name ?? "",
        fullName: [aNewMember.first_name, aNewMember.last_name]
          .join(" ")
          .trim(),
        designation: aNewMember.designation ?? "",
        companyName: aNewMember.company_name ?? "",
        companyAddress: aNewMember.company_address ?? "",
        subtitle: aNewMember.bio ?? "",
        profileBanner: "",
        profileImage: "",
        companyLogo: "",
        viewsCount: 0,
        leadsCount: 0,
        devicesCount: 0,
        locked: false,
        isPublic: true,
        openDirect: false,
        captureLead: false,
        connectButton: true,
        saveContactButton: true,
        role: UserRole.BUSINESS_USER,
      })),
      settings: response.data.settings?.map((aNewUserSettings) => ({
        colors: {
          id: aNewUserSettings.colors.id,
          userId: aNewUserSettings.colors.user_id,
          sectionColor:
            getRGBAString(aNewUserSettings.colors.section_color ?? "") ||
            SettingsColorsDefaults.SECTION_COLOR,
          profileColor:
            getRGBAString(aNewUserSettings.colors.bg_color ?? "") ||
            SettingsColorsDefaults.PROFILE_COLOR,
          borderColor:
            getRGBAString(aNewUserSettings.colors.photo_border_color ?? "") ||
            SettingsColorsDefaults.BORDER_COLOR,
          textColor:
            getRGBAString(aNewUserSettings.colors.text_color ?? "") ||
            SettingsColorsDefaults.TEXT_COLOR,
          controlButtonTextColor:
            getRGBAString(
              aNewUserSettings.colors.control_button_text_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
          controlButtonBgColor:
            getRGBAString(aNewUserSettings.colors.control_button_color ?? "") ||
            SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
          profileButtonIconColor:
            getRGBAString(
              aNewUserSettings.colors.profile_button_icon_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
          profileButtonBgColor:
            getRGBAString(
              aNewUserSettings.colors.profile_button_bg_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
          colorLinkIcons:
            getBoolean(aNewUserSettings.colors.color_link_icons) ||
            Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
        },
      })),
      links: response.data.links?.map((aNewLink) => ({
        id: aNewLink.id,
        linkTypeCode: aNewLink.profile_code,
        iconSVG: getSVGString(aNewLink.icon_url) || aNewLink.icon_svg || "",
        title: aNewLink.title,
        valueType: aNewLink.type,
        value: aNewLink.value,
        href: aNewLink.href,
        sequence: aNewLink.sequence,
        visible: Boolean(aNewLink.visible),
        isHighlighted: Boolean(aNewLink.is_highlighted),
        userId: aNewLink.user_id,
        globalId: aNewLink.global_id || null,
        templateId: aNewLink.template_id || null,
        isUnique: Boolean(aNewLink.is_unique),
        isAddedToContactCardBy:
          aNewLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aNewLink.template_link_id || null,
        syncedWith: null,
      })),
      ...(response.data.template && {
        assignedTemplate: {
          id: response.data.template.id,
          assigneeIds: response.data.template.assignees_ids.map((id) =>
            Number(id)
          ),
        },
      }),
    },
  };
}

export function getImportNewADUsersRequestBody(
  args: Omit<ImportNewADUsersRequestArgs, "integrationId">
): ImportNewADUsersRequestBody {
  return {
    users: args.selectedADUsers,
    settings: JSON.stringify(args.settings),
    send_invites: args.doSendInvites,
    template_id: args.templateId,
  };
}

export function transformImportNewADUsersResponse(
  response: ImportNewADUsersAPIResponse
): ImportNewADUsersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      newMembers: response.data.new_members?.map((aNewMember) => ({
        id: aNewMember.id,
        username: aNewMember.username,
        email: aNewMember.email,
        membership: aNewMember.is_pro,
        firstName: aNewMember.first_name ?? "",
        fullName: [aNewMember.first_name, aNewMember.last_name]
          .join(" ")
          .trim(),
        designation: aNewMember.designation ?? "",
        companyName: aNewMember.company_name ?? "",
        companyAddress: aNewMember.company_address ?? "",
        subtitle: "",
        profileBanner: "",
        profileImage: aNewMember.profile_image ?? "",
        companyLogo: "",
        viewsCount: 0,
        leadsCount: 0,
        devicesCount: 0,
        locked: false,
        isPublic: true,
        openDirect: false,
        captureLead: false,
        connectButton: true,
        saveContactButton: true,
        role: UserRole.BUSINESS_USER,
      })),
      newsettings: response.data.new_settings?.map((aNewUserSettings) => ({
        colors: {
          id: aNewUserSettings.colors.id,
          userId: aNewUserSettings.colors.user_id,
          sectionColor:
            getRGBAString(aNewUserSettings.colors.section_color ?? "") ||
            SettingsColorsDefaults.SECTION_COLOR,
          profileColor:
            getRGBAString(aNewUserSettings.colors.bg_color ?? "") ||
            SettingsColorsDefaults.PROFILE_COLOR,
          borderColor:
            getRGBAString(aNewUserSettings.colors.photo_border_color ?? "") ||
            SettingsColorsDefaults.BORDER_COLOR,
          textColor:
            getRGBAString(aNewUserSettings.colors.text_color ?? "") ||
            SettingsColorsDefaults.TEXT_COLOR,
          controlButtonTextColor:
            getRGBAString(
              aNewUserSettings.colors.control_button_text_color ?? ""
            ) || SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR,
          controlButtonBgColor:
            getRGBAString(aNewUserSettings.colors.control_button_color ?? "") ||
            SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR,
          profileButtonIconColor:
            getRGBAString(
              aNewUserSettings.colors.profile_button_icon_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR,
          profileButtonBgColor:
            getRGBAString(
              aNewUserSettings.colors.profile_button_bg_color ?? ""
            ) || SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR,
          colorLinkIcons:
            getBoolean(aNewUserSettings.colors.color_link_icons) ||
            Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
        },
      })),
      newLinks: response.data.new_links?.map((aNewLink) => ({
        id: aNewLink.id,
        linkTypeCode: aNewLink.profile_code,
        iconSVG: getSVGString(aNewLink.icon_url) || aNewLink.icon_svg || "",
        title: aNewLink.title,
        valueType: aNewLink.type,
        value: aNewLink.value,
        href: aNewLink.href,
        sequence: aNewLink.sequence,
        visible: Boolean(aNewLink.visible),
        isHighlighted: Boolean(aNewLink.is_highlighted),
        userId: aNewLink.user_id,
        globalId: aNewLink.global_id || null,
        templateId: aNewLink.template_id || null,
        isUnique: Boolean(aNewLink.is_unique),
        isAddedToContactCardBy:
          aNewLink.added_to_contact_card || AddedToContactCardBy.NONE,
        templateLinkId: aNewLink.template_link_id || null,
        syncedWith: aNewLink.synced_with || null,
      })),
      ...(response.data.updated_template && {
        assignedTemplate: {
          id: response.data.updated_template.id,
          assigneeIds: response.data.updated_template.assignees_ids.map((id) =>
            Number(id)
          ),
        },
      }),
      syncedMembers:
        response.data.new_members?.map((aNewMember) => ({
          memberId: aNewMember.id,
          integrationId: response.data.integration_id,
          settings: JSON.parse(response.data.settings),
        })) || [],
    },
  };
}

export function getSyncExistingADUsersRequestBody(
  args: Omit<SyncExistingADUsersRequestArgs, "integrationId">
): SyncExistingADUsersRequestBody {
  return {
    users: args.selectedADUsers,
    settings: JSON.stringify(args.settings),
  };
}

export function transformSyncExistingADUsersResponse(
  response: SyncExistingADUsersAPIResponse
): SyncExistingADUsersResponse {
  return {
    success: response.success,
    message: response.message,
    data: {
      updatedMembers: response.data.updated_members?.map((anUpdatedMember) => ({
        id: anUpdatedMember.id,
        ...(anUpdatedMember.first_name !== undefined && {
          firstName: anUpdatedMember.first_name ?? "",
        }),
        ...(anUpdatedMember.first_name !== undefined &&
          anUpdatedMember.last_name !== undefined && {
            fullName: [anUpdatedMember.first_name, anUpdatedMember.last_name]
              .join(" ")
              .trim(),
          }),
        ...(anUpdatedMember.designation !== undefined && {
          designation: anUpdatedMember.designation ?? "",
        }),
        ...(anUpdatedMember.company_name !== undefined && {
          companyName: anUpdatedMember.company_name ?? "",
        }),
        ...(anUpdatedMember.company_address !== undefined && {
          companyAddress: anUpdatedMember.company_address ?? "",
        }),
      })),
      updatedLinks: response.data.updated_buttons?.map((anUpdatedLink) => ({
        id: anUpdatedLink.id,
        value: anUpdatedLink.value ?? "",
      })),
      syncedMembers: response.data.updated_members?.map((anUpdatedMember) => ({
        memberId: anUpdatedMember.id,
        integrationId: response.data.integration_id,
        settings: JSON.parse(response.data.settings),
      })),
    },
  };
}
