import { PlaylistAddCheck as PlaylistAddCheckIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useUpdateProductDetailsMutation } from "../features/devices/devicesService";
import { editProduct, getProductById } from "../features/devices/devicesSlice";
import type { MemberDevice } from "../features/devices/devicesTypes";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";
import theme, { TextFieldX } from "../styles/mui-styled";

type EditProductDetailsDialogProps = DialogProps & {
  productId: MemberDevice["id"];
  onSave?: () => void;
  onCancel?: () => void;
};

function EditProductDetailsDialog({
  productId,
  onSave,
  onCancel,
  className = "",
  ...props
}: EditProductDetailsDialogProps) {
  const [thisProductName, setThisProductName] =
    useState<MemberDevice["name"]>("");

  const dispatch = useAppDispatch();

  const thisProduct = useAppSelector((state: RootState) =>
    getProductById(state, productId)
  );

  useEffect(() => {
    if (thisProduct) {
      setThisProductName(thisProduct.name);
    }
  }, [thisProduct]);

  const [updateProductDetails, { isLoading }] =
    useUpdateProductDetailsMutation();

  async function handleSaveClick() {
    if (!thisProduct) return;

    try {
      const {
        success,
        message,
        data: updatedProduct,
      } = await updateProductDetails({
        id: thisProduct.id,
        name: thisProductName,
      }).unwrap();
      if (success) {
        dispatch(editProduct(updatedProduct));
        toast.success(message);
        onSave?.();
      } else {
        toast.error(message);
      }
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  return (
    <Dialog
      classes={{
        paper: `!shadow-gray-900/8 isolate min-w-96 gap-4 !overflow-visible !rounded-2xl p-8 !shadow-xl ${className}`,
      }}
      {...props}
    >
      <DialogTitle className="!p-0 !font-sans !font-semibold text-black">
        Edit Device Details
      </DialogTitle>
      <DialogContent className="!p-0 !pt-2">
        <TextFieldX
          label="Device Name"
          name="product-name"
          value={thisProductName}
          onChange={(e) => setThisProductName(e.target.value)}
          error={thisProductName.length < 3}
          helperText="The device name must be at least 3 characters."
          fullWidth
        />
      </DialogContent>
      <DialogActions className="gap-4 !border-t border-gray-200 !p-0 !pt-4">
        <Button
          variant="outlined"
          onClick={() => onCancel?.()}
          className="!m-0 !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black"
        >
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          startIcon={<PlaylistAddCheckIcon />}
          loadingPosition="start"
          loading={isLoading}
          onClick={handleSaveClick}
          disabled={
            thisProductName === thisProduct?.name || thisProductName.length < 3
          }
          classes={theme.LoadingButtonX}
        >
          Save Changes
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default EditProductDetailsDialog;
