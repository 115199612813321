import { useState } from "react";
import { toast } from "react-toastify";
import {
  ConfirmationDialog,
  EditProductDetailsDialog,
  MemberProductsList,
  Spinner,
} from "../../components";
import APIConfigs from "../../configs/apiConfigs";
import useMember from "../../hooks/useMember";
import type { MemberDevice } from "../devices/devicesTypes";
import type { Member } from "../members/membersTypes";

type ManageDevicesProps = {
  member: Member;
  devices?: MemberDevice[];
  className?: string;
};

function ManageDevices({
  member,
  devices,
  className,
  ...props
}: ManageDevicesProps) {
  const [showConfirmationDialog, setConfirmationDialog] =
    useState<boolean>(false);
  const [deviceIdsToUnmap, setDeviceIdsToUnmap] = useState<
    Array<MemberDevice["id"]>
  >([]);
  const [showEditDialog, setEditDialog] = useState<boolean>(false);
  const [productIdToEdit, setProductIdToEdit] = useState<MemberDevice["id"]>();

  const { unmapDevices, isLoading } = useMember(member.id);

  function confirmUnmapDevice(ids: Array<MemberDevice["id"]>) {
    setDeviceIdsToUnmap(ids);
    setConfirmationDialog(true);
  }

  async function handleConfirmUnmapDevice() {
    if (!deviceIdsToUnmap.length) return;
    setConfirmationDialog(false);

    const { success, message } = await unmapDevices(deviceIdsToUnmap);
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }

    setDeviceIdsToUnmap([]);
  }

  function handleCancelUnmapDevice() {
    setDeviceIdsToUnmap([]);
    setConfirmationDialog(false);
  }

  function editProductDetails(id: any) {
    setProductIdToEdit(id);
    setEditDialog(true);
  }

  function closeEditDialog() {
    setProductIdToEdit(undefined);
    setEditDialog(false);
  }

  return !devices ? (
    <div className="relative h-2/3">
      <Spinner className="bg-transparent" />
    </div>
  ) : devices.length > 0 ? (
    <section className={`flex flex-col gap-8 ${className ?? ""}`} {...props}>
      <div>
        <h4 className="text-xl font-semibold text-black">Manage Devices</h4>
        <p className="text-sm text-gray-500">
          The devices assigned to this member are listed below. You can unmap
          any device.
        </p>
      </div>
      <MemberProductsList
        products={devices}
        onProductEdit={editProductDetails}
        onProductsUnmap={confirmUnmapDevice}
        isLoading={isLoading}
      />
      {productIdToEdit && (
        <EditProductDetailsDialog
          productId={productIdToEdit}
          onSave={closeEditDialog}
          onCancel={closeEditDialog}
          open={showEditDialog}
        />
      )}
      <ConfirmationDialog
        title="Confirm Deletion"
        content={`Are you sure you want to unmap ${
          deviceIdsToUnmap.length > 1 ? "these devices" : "this device"
        }? This action cannot be undone.`}
        confirmButtonText="Confirm"
        onConfirm={handleConfirmUnmapDevice}
        onCancel={handleCancelUnmapDevice}
        open={showConfirmationDialog}
        danger
      />
    </section>
  ) : (
    <div className={`flex flex-col gap-8 ${className ?? ""}`} {...props}>
      <h4 className="text-xl font-semibold text-black">Add Devices</h4>
      <div className="flex flex-col items-center justify-center gap-8 rounded-[1.25rem] bg-gray-100 p-16">
        <h5 className="text-xl font-semibold text-black">
          {`Activate devices to ${
            member.firstName || member.fullName || member.username
          }'s profile`}
        </h5>
        <div className="flex rounded-xl bg-white p-8 shadow-2xl shadow-gray-900/20">
          <img
            src={`${APIConfigs.API_BASE_URL}/members/${member.id}/qrcode`}
            alt={`QR Code for ${
              member.firstName || member.fullName || member.username
            }'s profile`}
            className="aspect-square w-56"
          />
        </div>
        <div className="flex w-80 flex-col gap-6 text-center text-gray-700">
          <p>
            Scan the AddMee code with your phone to launch the activation flow
          </p>
          <p>Use your app to activate devices</p>
        </div>
      </div>
    </div>
  );
}

export default ManageDevices;
