import {
  Button,
  Checkbox,
  FormControl,
  RadioGroup,
  TextField,
  buttonClasses,
  checkboxClasses,
  formControlLabelClasses,
  formGroupClasses,
  formLabelClasses,
  iconButtonClasses,
  inputLabelClasses,
  outlinedInputClasses,
  radioClasses,
  styled,
  svgIconClasses,
  tableCellClasses,
  tableClasses,
  tableContainerClasses,
  typographyClasses,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import defaultTheme, { colors } from "tailwindcss/defaultTheme";

const thisTheme = {
  ...defaultTheme,
  fontFamily: {
    sans: ['"Inter"', ...defaultTheme.fontFamily.sans],
  },
  colors: {
    ...colors,
    primary: {
      DEFAULT: "#702C89",
      50: "#F9F5FF",
    },
    error: {
      200: "#FECDCA",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
    },
  },
};

const muiStyled = `
  & .${inputLabelClasses.root}, & .${outlinedInputClasses.input} {
    font-family: "Inter";
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  & .${inputLabelClasses.root} {
    -webkit-transform: translate(24px, 16px) scale(1);
    -moz-transform: translate(24px, 16px) scale(1);
    -ms-transform: translate(24px, 16px) scale(1);
    transform: translate(24px, 16px) scale(1);

    &.${inputLabelClasses.sizeSmall} {
      -webkit-transform: translate(24px, 12px) scale(1);
      -moz-transform: translate(24px, 12px) scale(1);
      -ms-transform: translate(24px, 12px) scale(1);
      transform: translate(24px, 12px) scale(1);
    }

    &:not(.${outlinedInputClasses.error}) {
      color: #9ca3af;
    }

    &.${inputLabelClasses.shrink} {
      font-size: 0.75rem;
      line-height: 1rem;

      -webkit-transform: translate(32px, -8px) scale(1);
      -moz-transform: translate(32px, -8px) scale(1);
      -ms-transform: translate(32px, -8px) scale(1);
      transform: translate(32px, -8px) scale(1);

      &:not(.${outlinedInputClasses.error}) {
        color: #4b5563;
      }
    }
  }

  & .${outlinedInputClasses.input} {
    color: #000000;
    height: 1.25rem;
    padding: 1rem 1.5rem;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      background-color: transparent;
    }
  }

  & .${outlinedInputClasses.sizeSmall} .${outlinedInputClasses.input} {
    padding: 0.75rem 1.5rem;
  }

  & .${outlinedInputClasses.notchedOutline} {
    border-color: #e5e7eb;
    border-radius: 9999px;
    border-width: 2px;

    legend {
      margin-left: 1rem;

      span:not(.notranslate) {
        padding: 0 0.5rem;
      }
    }
  }

  & .${outlinedInputClasses.disabled} .${outlinedInputClasses.notchedOutline} {
    border-color: #f3f4f6;
  }


  &
    .${outlinedInputClasses.root}:not(.${outlinedInputClasses.error}).${outlinedInputClasses.focused}
    .${outlinedInputClasses.notchedOutline} {
    border-color: #4b5563;
  }

  & .${iconButtonClasses.root} {
    right: 0.375rem;

    .${svgIconClasses.root} {
      font-size: 1.25rem;
    }
  }
`;

export const ButtonX = styled(Button)`
  &.${buttonClasses.root} {
    border-radius: 9999px;
    color: ${thisTheme.colors.primary.DEFAULT};
    font-family: ${thisTheme.fontFamily.sans.join(",")};
    font-weight: ${thisTheme.fontWeight.semibold};
    line-height: 1.125rem;
    padding: 0.5rem 1rem;
    text-transform: none;

    &.${buttonClasses.colorError} {
      color: ${thisTheme.colors.error[500]};
    }

    &.${buttonClasses.sizeSmall} {
      font-size: 0.75rem !important;
    }

    &.${buttonClasses.disabled} {
      color: rgba(0, 0, 0, 0.26) !important;
    }
`;

export const TextFieldX = styled(TextField)`
  ${muiStyled}
`;

export const DatePickerX = styled(DatePicker)`
  ${muiStyled}
`;

export const SelectX = styled(FormControl)`
  ${muiStyled}
`;

export const FormControlMultiSelect = styled(FormControl)`
  ${muiStyled}
`;

export const RadioGroupX = styled(RadioGroup)`
  & .${formControlLabelClasses.label} {
    font-family: ${thisTheme.fontFamily.sans.join(",")};
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  & .${radioClasses.colorPrimary}:not(.${radioClasses.disabled}) {
    color: ${thisTheme.colors.primary.DEFAULT};
  }
`;

export const CheckboxX = styled(Checkbox)`
  &.${checkboxClasses.checked}, &.${checkboxClasses.indeterminate} {
    color: ${thisTheme.colors.primary.DEFAULT};

    &.danger {
      color: ${thisTheme.colors.error[400]};
    }
  }
`;

export const FormControlAutoSync = styled(FormControl)`
  & .${formLabelClasses.root}, & .${typographyClasses.root} {
    font-family: "Inter";
    font-size: ${defaultTheme.fontSize.sm};
  }

  & .${formLabelClasses.root} {
    font-weight: ${defaultTheme.fontWeight.semibold};

    &.${formLabelClasses.focused} {
      color: ${thisTheme.colors.primary.DEFAULT};
    }
  }

  & .${formGroupClasses.root} {
    column-gap: ${defaultTheme.spacing["4"]};
    padding-left: ${defaultTheme.spacing["4"]};
  }
`;

export const FormControlSyncFields = styled(FormControl)`
  &
    .${formLabelClasses.root},
    &
    .${tableCellClasses.root},
    &
    .${typographyClasses.root} {
    font-family: "Inter";
    font-size: ${defaultTheme.fontSize.sm};
  }

  & .${formLabelClasses.root} {
    font-weight: ${defaultTheme.fontWeight.semibold};

    &.${formLabelClasses.focused} {
      color: ${thisTheme.colors.primary.DEFAULT};
    }
  }

  & .${tableContainerClasses.root} {
    padding: ${defaultTheme.spacing["1"]} 0;
  }

  & .${tableClasses.root} {
    width: auto;

    th.${tableCellClasses.root} {
      font-weight: ${defaultTheme.fontWeight.medium};
    }

    .${tableCellClasses.root} {
      border-bottom: ${defaultTheme.borderWidth[0]};

      .${formControlLabelClasses.root} {
        position: relative;
        padding: ${defaultTheme.spacing["px"]} 0 0 ${defaultTheme.spacing["9"]};

        .${checkboxClasses.root} {
          position: absolute;
          top: -${defaultTheme.spacing["2"]};
          left: ${defaultTheme.spacing["0"]};
        }
      }
    }
  }
`;

const theme = {
  LoadingButtonX: {
    root: "!m-0 !items-center !justify-center !rounded-full !bg-primary !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-white disabled:!bg-gray-300",
    loading: "disabled:!bg-primary",
    loadingIndicator: "!left-[1.125rem] !text-white",
  },
  LoadingButtonXDanger: {
    root: "!m-0 !items-center !justify-center !rounded-full !bg-error-400 !font-sans !font-semibold !normal-case !text-white disabled:!bg-gray-300",
    loading: "disabled:!bg-error-400",
    loadingIndicator: "!left-[1.125rem] !text-white",
    sizeSmall: "!px-4 !py-2 !text-xs",
    sizeMedium: "!px-6 !py-3 !text-sm",
  },
  LoadingButtonXNoStartIcon: {
    root: "!m-0 !items-center !justify-center !rounded-full !bg-primary !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-white disabled:!bg-gray-300",
    loading: "disabled:!bg-primary",
    loadingIndicator: "!relative !left-0 -ml-4 !text-white",
  },
  OutlinedButtonX: {
    root: "!items-center !justify-center !rounded-full !border-gray-200 !bg-white !px-6 !py-3 !font-sans !text-sm !font-semibold !normal-case !text-black",
  },
};

export default theme;
