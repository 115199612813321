import { Avatar } from "@mui/material";
import {
  DataGrid,
  DataGridProps,
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import type { ADUser } from "../features/integrations/integrationsTypes";
import { MemberDataLabels } from "../features/members/membersEnums";

const syncGridColumns: GridColDef[] = [
  {
    field: "profilePicture",
    headerName: MemberDataLabels.PROFILE_PICTURE,
    sortable: false,
    renderCell: ({ row: anADUser }: GridRenderCellParams<ADUser>) => (
      <Avatar
        src={anADUser.profilePicture || undefined}
        className="!h-12 !w-12 !bg-gray-100 !text-gray-400"
        aria-label={anADUser.displayName}
      />
    ),
    width: 68,
  },
  {
    field: "displayName",
    headerName: MemberDataLabels.CARD_TITLE,
    flex: 1,
    cellClassName: "font-medium",
  },
  {
    field: "jobTitle",
    headerName: MemberDataLabels.JOB_TITLE,
    sortable: false,
  },
  {
    field: "companyName",
    headerName: MemberDataLabels.COMPANY,
    sortable: false,
  },
  {
    field: "officeLocation",
    headerName: MemberDataLabels.LOCATION,
    sortable: false,
  },
  {
    field: "businessPhones",
    headerName: "Business Phones",
    sortable: false,
    renderCell: ({ row: anADUser }: GridRenderCellParams<ADUser>) =>
      anADUser.businessPhones.join(", "),
  },
  {
    field: "mobilePhone",
    headerName: "Mobile Phone",
    sortable: false,
  },
];

type SyncADMembersProps = Omit<DataGridProps, "rows" | "columns"> & {
  adUsers: ADUser[];
  selectedUserIds?: GridRowSelectionModel;
  onSelectionChange?: (selectedUserIds: GridRowSelectionModel) => void;
};

function SyncADMembers({
  adUsers,
  selectedUserIds,
  onSelectionChange,
  className = "",
  ...props
}: SyncADMembersProps) {
  const [thisSelectedUserIds, setThisSelectedUserIds] =
    useState<GridRowSelectionModel>();

  useEffect(() => {
    setThisSelectedUserIds(
      selectedUserIds?.filter((id) => adUsers.some((user) => user.id === id))
    );
  }, [adUsers, selectedUserIds, setThisSelectedUserIds]);

  function handleSelectionChange(
    selectedUserIds: GridRowSelectionModel,
    _details: GridCallbackDetails<any>
  ): void {
    setThisSelectedUserIds(selectedUserIds);
    onSelectionChange?.(selectedUserIds);
  }

  return (
    <DataGrid
      getRowId={(row) => row.id}
      rows={adUsers}
      columns={syncGridColumns}
      checkboxSelection
      disableRowSelectionOnClick
      autoHeight
      disableColumnMenu
      classes={{
        root: `!font-sans text-sm text-gray-900 ${className}`,
        columnHeaderTitle: "!font-bold text-black",
        row: "hover:!bg-primary-50",
      }}
      sx={{
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#F9F5FF",
        },
        "& .MuiCheckbox-root.Mui-checked svg": {
          color: "#702C89 !important",
        },
      }}
      rowSelectionModel={thisSelectedUserIds}
      onRowSelectionModelChange={handleSelectionChange}
      {...props}
    />
  );
}

export default SyncADMembers;
