import {
  DeleteOutlined as DeleteOutlinedIcon,
  Deselect as DeselectIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
  SelectAll as SelectAllIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListProps,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import type { MemberDevice } from "../features/devices/devicesTypes";
import { ButtonX, CheckboxX } from "../styles/mui-styled";
import Spinner from "./Spinner";

type MemberProductsListProps = ListProps & {
  products: MemberDevice[];
  onProductEdit?: (id: MemberDevice["id"]) => void;
  onProductsUnmap?: (ids: Array<MemberDevice["id"]>) => void;
  isLoading?: boolean;
};

function MemberProductsList({
  products,
  onProductEdit,
  onProductsUnmap,
  isLoading = false,
  className = "",
  ...props
}: MemberProductsListProps) {
  const [productIdToUnmap, setProductIdToUnmap] =
    useState<MemberDevice["id"]>();
  const [selectedProductIds, setSelectedProductIds] = useState<
    Array<MemberDevice["id"]>
  >([]);

  useEffect(() => {
    return () => {
      setProductIdToUnmap(undefined);
    };
  }, []);

  function handleToggleSelection(productId: MemberDevice["id"]) {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds((prevSelected) =>
        prevSelected.filter((id) => id !== productId)
      );
    } else {
      setSelectedProductIds((prevSelected) => [...prevSelected, productId]);
    }
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-1">
        {selectedProductIds.length < products.length ? (
          <ButtonX
            size="small"
            startIcon={<SelectAllIcon />}
            onClick={() => setSelectedProductIds(products.map((p) => p.id))}
            className="!min-w-[8.125rem] !justify-start"
          >
            Select All
          </ButtonX>
        ) : (
          <ButtonX
            size="small"
            startIcon={<DeselectIcon />}
            onClick={() => setSelectedProductIds([])}
            className="!min-w-[8.125rem] !justify-start"
          >
            Deselect All
          </ButtonX>
        )}
        <Divider orientation="vertical" variant="middle" flexItem />
        <ButtonX
          size="small"
          startIcon={<DeleteOutlinedIcon />}
          color="error"
          onClick={() => {
            setProductIdToUnmap(undefined);
            onProductsUnmap?.(selectedProductIds);
          }}
          disabled={selectedProductIds.length === 0}
        >
          Unmap Selected
        </ButtonX>
      </div>
      <List className={`flex flex-col gap-4 !p-0 ${className}`} {...props}>
        {products.map((aProduct) => (
          <ListItem
            key={`member-device-${aProduct.id}`}
            secondaryAction={
              <>
                <IconButton
                  aria-label="Rename Device"
                  onClick={() => onProductEdit?.(aProduct.id)}
                >
                  <EditNoteOutlinedIcon className="!text-xl text-primary" />
                </IconButton>
                <IconButton
                  aria-label="Unmap Device"
                  onClick={() => {
                    setProductIdToUnmap(aProduct.id);
                    onProductsUnmap?.([aProduct.id]);
                  }}
                >
                  <DeleteOutlinedIcon className="!text-xl text-error-500" />
                </IconButton>
              </>
            }
            className="!p-0"
          >
            <ListItemButton
              role={undefined}
              onClick={() => handleToggleSelection(aProduct.id)}
              className={`!justify-between gap-3 !rounded-2xl ${
                selectedProductIds.includes(aProduct.id)
                  ? "!bg-error-50"
                  : "!bg-gray-50"
              } !p-4`}
              dense
            >
              <ListItemIcon className="!min-w-10">
                <CheckboxX
                  size="small"
                  checked={selectedProductIds.includes(aProduct.id)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": `checkbox-list-label-${aProduct.id}`,
                  }}
                  className="danger"
                />
              </ListItemIcon>
              <ListItemAvatar>
                <Avatar
                  variant="rounded"
                  alt={aProduct.name}
                  src={aProduct.productImage}
                  className="!h-[3.375rem] !w-[3.375rem] !rounded-[0.658rem]"
                />
              </ListItemAvatar>
              <div className="grow py-1">
                <Typography className="!font-sans !text-sm text-gray-400">
                  <strong
                    aria-label="Product Name"
                    className="text-base font-semibold text-black"
                  >
                    {aProduct.name}
                  </strong>
                  {aProduct.name && aProduct.serialNumber && " \u2014 "}
                  <span aria-label="Serial Number">
                    {aProduct.serialNumber}
                  </span>
                </Typography>
                <Typography className="!font-sans !text-sm text-gray-400">
                  <span aria-label="Product Type" className="text-gray-800">
                    {aProduct.productType}
                  </span>
                  {aProduct.productType && aProduct.activatedOn && ` | `}
                  <span aria-label="Activation Date">
                    {new Date(aProduct.activatedOn).toLocaleString("en-US", {
                      dateStyle: "long",
                    })}
                  </span>
                </Typography>
                {/* {(aProduct.lastScannedOn || aProduct.lastScannedAt) && (
                  <Typography className="!font-sans">
                    <small className="text-xs text-gray-300">
                      {`This device was last scanned ${
                        aProduct.lastScannedOn
                          ? `on ${new Date(
                              aProduct.lastScannedOn
                            ).toLocaleString("en-US", {
                              dateStyle: "long",
                              timeStyle: "short",
                              hour12: true,
                            })}`
                          : ""
                      } ${
                        aProduct.lastScannedAt
                          ? `at ${aProduct.lastScannedAt}`
                          : ""
                      }`}
                    </small>
                  </Typography>
                )} */}
              </div>
              {isLoading &&
                (productIdToUnmap === aProduct.id ||
                  (!productIdToUnmap &&
                    selectedProductIds.includes(aProduct.id))) && (
                  <Spinner className="rounded-2xl !bg-error-200/25" />
                )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}

export default MemberProductsList;
