export enum AddMembersBySections {
  EMAILS = 0,
  CSV = 1,
  ACTIVE_DIRECTORY = 2,
}

export function getAddMembersBySectionLabel(
  value: AddMembersBySections
): string {
  return value === AddMembersBySections.CSV
    ? "Invite via CSV"
    : value === AddMembersBySections.ACTIVE_DIRECTORY
    ? "Sync Active Directory"
    : "Add by Emails";
}

export function getAddMembersByHashTag(value: AddMembersBySections): string {
  return value === AddMembersBySections.CSV
    ? "#InviteViaCSV"
    : value === AddMembersBySections.ACTIVE_DIRECTORY
    ? "#SyncActiveDirectory"
    : "#AddByEmails";
}

export function getAddMembersBySection(value: string): AddMembersBySections {
  return value === "#InviteViaCSV"
    ? AddMembersBySections.CSV
    : value === "#SyncActiveDirectory"
    ? AddMembersBySections.ACTIVE_DIRECTORY
    : AddMembersBySections.EMAILS;
}

export enum AddMembersByADSections {
  NEW = 0,
  EXISTING = 1,
}

export function getAddMembersByADSectionLabel(
  value: AddMembersByADSections
): string {
  return value === AddMembersByADSections.EXISTING
    ? "Update Existing"
    : "Import New";
}
