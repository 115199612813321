import { createApi } from "@reduxjs/toolkit/query/react";
import APIConfigs from "../../configs/apiConfigs";
import { baseQueryWithReauth } from "../rtk-query/baseQuery";
import {
  mapReadDevicesResponse,
  transformUpdateProductDetailsResponse,
} from "./devicesMapper";
import type {
  MemberDevice,
  ReadDevicesResponse,
  UnmapDevicesResponse,
  UpdateProductDetailsRequestArgs,
  UpdateProductDetailsResponse,
} from "./devicesTypes";

const devicesService = createApi({
  reducerPath: "devicesService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    readDevices: builder.query<ReadDevicesResponse, void>({
      query: () => APIConfigs.Devices.READ_DEVICES,
      transformResponse: mapReadDevicesResponse,
    }),
    unmapDevices: builder.mutation<
      UnmapDevicesResponse,
      Array<MemberDevice["id"]>
    >({
      query: (ids) => ({
        url: APIConfigs.Devices.UNMAP_DEVICES,
        method: "PUT",
        body: { ids },
      }),
    }),
    updateProductDetails: builder.mutation<
      UpdateProductDetailsResponse,
      UpdateProductDetailsRequestArgs
    >({
      query: ({ id, ...args }) => ({
        url: APIConfigs.Products.UPDATE_A_PRODUCT.replace(":id", id.toString()),
        method: "PUT",
        body: args,
      }),
      transformResponse: transformUpdateProductDetailsResponse,
    }),
  }),
});

export default devicesService;
export const {
  useReadDevicesQuery,
  useUnmapDevicesMutation,
  useUpdateProductDetailsMutation,
} = devicesService;
