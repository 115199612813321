import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faArrowLeft, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuItem from "@mui/material/MenuItem";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { AzureLogoGray } from "../assets/images/brands";
import {
  FloatingSelect,
  PhonePreview,
  Spinner,
  tabPanelA11yProps,
  TabsX,
  TabX,
} from "../components";
import {
  AddMembersByCSVSubmitData,
  AddMembersByEmailsFormData,
  AddMembersBySections,
  CreateMembersResponse,
  getAddMembersByHashTag,
  getAddMembersBySection,
  getAddMembersBySectionLabel,
  useCreateMembersByCSVMutation,
  useCreateMembersByEmailMutation,
} from "../features/add-members";
import AddByEmail from "../features/add-members/AddByEmail";
import ImportViaCSV from "../features/add-members/ImportViaCSV";
import SyncActiveDirectory from "../features/add-members/SyncActiveDirectory";
import { MemberLink, addNewLinks } from "../features/links";
import {
  addMembers,
  addNewMemberSettings,
} from "../features/members/membersSlice";
import type { MemberSettings } from "../features/members/membersTypes";
import { SettingsColorsDefaults } from "../features/settings/settingsEnums";
import {
  AppTemplate,
  Template,
  editTemplateAssignees,
} from "../features/templates";
import { Platforms } from "../features/integrations/integrationsEnums";
import { getIntegrationsByPlatformName } from "../features/integrations/integrationsSlice";
import useIntegrations from "../hooks/useIntegrations";
import useTemplates from "../hooks/useTemplates";
import { RootState, useAppDispatch, useAppSelector } from "../store";
import { FetchError, handleFetchError } from "../utils";
import AddMeeApp from "./addmee-app";

function AddMembers() {
  const [tabIndex, setTabIndex] = useState<AddMembersBySections>(
    getAddMembersBySection(window.location.hash)
  );
  const [selectedTemplateId, setSelectedTemplateId] =
    useState<Template["id"]>();
  const [appTemplate, setAppTemplate] = useState<AppTemplate>();
  const [appTemplateLinks, setAppTemplateLinks] = useState<MemberLink[]>();
  const [appSettings, setAppSettings] = useState<MemberSettings>();

  const dispatch = useAppDispatch();

  const {
    templates,
    templateLinks,
    templateSettings,
    isLoading: isreadTemplatesPending,
  } = useTemplates();
  const { isLoading: isIntegrationsLoading } = useIntegrations();

  const activeDirectoryIntegration = useAppSelector(
    (state: RootState) =>
      getIntegrationsByPlatformName(state, Platforms.ACTIVE_DIRECTORY)?.[0]
  );

  function handleTabXChange(
    _event: SyntheticEvent<Element, Event>,
    value: AddMembersBySections
  ): void {
    setTabIndex(value);
    window.location.hash = getAddMembersByHashTag(value);
  }

  useEffect(() => {
    const handleHashChange = () => {
      setTabIndex(getAddMembersBySection(window.location.hash));
    };

    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  useEffect(() => {
    if (templates) {
      setSelectedTemplateId(
        selectedTemplateId || templates.find((t) => t.isDefault)?.id
      );
    }
  }, [templates, selectedTemplateId]);

  useEffect(() => {
    if (templates && templateLinks && templateSettings) {
      const template = templates.find((t) => t.id === selectedTemplateId);

      setAppTemplate({
        ...(template || {
          name: "",
          username: "",
          fullName: "",
          designation: "",
          companyName: "",
          companyAddress: "",
          subtitle: "",
          profileBanner: "",
          profileImage: "",
          companyLogo: "",
          captureLead: false,
          openDirect: false,
          connectButton: true,
          saveContactButton: true,
          isColorsCustomLocked: false,
        }),
        username: "",
        fullName: "",
        designation: "",
      });

      setAppTemplateLinks(
        templateLinks
          .filter((tl) => tl.templateId === selectedTemplateId)
          .map((tl) => ({
            ...tl,
            userId: -1,
            globalId: null,
            templateLinkId: null,
            syncedWith: null,
          }))
      );

      const templateColors = templateSettings.find(
        (ts) => ts.templateId === selectedTemplateId
      )?.colors;
      setAppSettings({
        colors: {
          id: -1,
          userId: -1,
          ...(templateColors || {
            sectionColor: String(SettingsColorsDefaults.SECTION_COLOR),
            profileColor: String(SettingsColorsDefaults.PROFILE_COLOR),
            borderColor: String(SettingsColorsDefaults.BORDER_COLOR),
            textColor: String(SettingsColorsDefaults.TEXT_COLOR),
            controlButtonTextColor: String(
              SettingsColorsDefaults.CONTROL_BUTTON_TEXT_COLOR
            ),
            controlButtonBgColor: String(
              SettingsColorsDefaults.CONTROL_BUTTON_BG_COLOR
            ),
            profileButtonIconColor: String(
              SettingsColorsDefaults.PROFILE_BUTTON_ICON_COLOR
            ),
            profileButtonBgColor: String(
              SettingsColorsDefaults.PROFILE_BUTTON_BG_COLOR
            ),
            colorLinkIcons: Boolean(SettingsColorsDefaults.COLOR_LINK_ICONS),
          }),
        },
      });
    }
  }, [selectedTemplateId, templates, templateLinks, templateSettings]);

  function createMembers({
    success,
    message,
    data: { members, settings, links, assignedTemplate },
  }: CreateMembersResponse) {
    if (success) {
      dispatch(addMembers(members ?? []));
      dispatch(addNewMemberSettings(settings ?? []));
      dispatch(addNewLinks(links ?? []));
      if (assignedTemplate) {
        dispatch(editTemplateAssignees(assignedTemplate));
      }
      toast.success(message);
    } else {
      toast.error(message);
    }
  }

  const [
    createMembersByEmail,
    { isLoading: isByEmailPending, isSuccess: isByEmailDone },
  ] = useCreateMembersByEmailMutation();

  async function handleAddByEmailSubmit({
    newMembers,
    doSendInvites,
  }: AddMembersByEmailsFormData) {
    try {
      createMembers(
        await createMembersByEmail({
          newMembers,
          doSendInvites,
          ...(selectedTemplateId &&
            selectedTemplateId > -1 && { templateId: selectedTemplateId }),
        }).unwrap()
      );
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  const [
    createMembersByCSV,
    { isLoading: isByCSVPending, isSuccess: isByCSVDone },
  ] = useCreateMembersByCSVMutation();

  async function handleImportViaCSVSubmit({
    file,
    doSendInvites,
    mappedFields,
  }: AddMembersByCSVSubmitData) {
    try {
      createMembers(
        await createMembersByCSV({
          file,
          doSendInvites,
          mappedFields,
          ...(selectedTemplateId &&
            selectedTemplateId > -1 && { templateId: selectedTemplateId }),
        }).unwrap()
      );
    } catch (error) {
      handleFetchError(error as FetchError);
    }
  }

  if (isreadTemplatesPending || isIntegrationsLoading) {
    return <Spinner fullScreen />;
  }

  return (
    <div className="relative p-8 pt-[7.25rem]">
      <div className="absolute inset-0 bottom-auto flex h-[7.25rem] p-8">
        <Link
          to="/members"
          className="flex w-[7.5rem] items-center justify-center gap-2 rounded-full border border-gray-200 bg-white text-sm text-black"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span className="font-semibold">Back</span>
        </Link>
      </div>
      <div className="flex flex-col gap-8 rounded-2xl bg-white px-7 py-8">
        <TabsX name="Add Members" value={tabIndex} onChange={handleTabXChange}>
          <TabX
            icon={<FontAwesomeIcon icon={faEnvelope} />}
            label={getAddMembersBySectionLabel(AddMembersBySections.EMAILS)}
            index={AddMembersBySections.EMAILS}
          />
          <TabX
            icon={<FontAwesomeIcon icon={faFileCsv} />}
            label={getAddMembersBySectionLabel(AddMembersBySections.CSV)}
            index={AddMembersBySections.CSV}
          />
          {activeDirectoryIntegration && (
            <TabX
              icon={<AzureLogoGray className="h-3.5 w-3.5" />}
              label={getAddMembersBySectionLabel(
                AddMembersBySections.ACTIVE_DIRECTORY
              )}
              index={AddMembersBySections.ACTIVE_DIRECTORY}
            />
          )}
        </TabsX>
        <div className="flex flex-col gap-7 md:flex-row">
          <div className="grow">
            {tabIndex === AddMembersBySections.EMAILS && (
              <AddByEmail
                onSubmit={handleAddByEmailSubmit}
                inProgress={isByEmailPending}
                isSuccess={isByEmailDone}
                {...tabPanelA11yProps(AddMembersBySections.EMAILS)}
              />
            )}
            {tabIndex === AddMembersBySections.CSV && (
              <ImportViaCSV
                onSubmit={handleImportViaCSVSubmit}
                inProgress={isByCSVPending}
                isSuccess={isByCSVDone}
                {...tabPanelA11yProps(AddMembersBySections.CSV)}
              />
            )}
            {activeDirectoryIntegration &&
              tabIndex === AddMembersBySections.ACTIVE_DIRECTORY && (
                <SyncActiveDirectory
                  templateId={
                    selectedTemplateId !== -1 ? selectedTemplateId : undefined
                  }
                  onTemplateChange={setSelectedTemplateId}
                  {...tabPanelA11yProps(AddMembersBySections.ACTIVE_DIRECTORY)}
                />
              )}
          </div>
          <div className="flex flex-col gap-4 rounded-3xl border border-gray-200 px-8 py-6">
            <h4 className="text-lg font-semibold text-black">
              Assign Template
            </h4>
            <FloatingSelect
              id="new-members-template"
              label="Select Template"
              value={selectedTemplateId}
              onChange={(v) => setSelectedTemplateId(v as Template["id"])}
              size="small"
            >
              {[
                <MenuItem key={-1} value={-1} className="!text-gray-400">
                  No Template
                </MenuItem>,
                ...(templates?.map((template) => (
                  <MenuItem key={template.id} value={template.id}>
                    {template.name}
                  </MenuItem>
                )) || []),
              ]}
            </FloatingSelect>
            <PhonePreview
              mutableData={
                appTemplate && {
                  id: selectedTemplateId,
                  captureLead: appTemplate.captureLead,
                  openDirect: appTemplate.openDirect,
                }
              }
            >
              {appTemplate && appTemplateLinks && appSettings && (
                <AddMeeApp
                  member={appTemplate}
                  links={appTemplateLinks}
                  settings={appSettings}
                />
              )}
            </PhonePreview>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddMembers;
